<template>
  <div class="container">
    <div class="row mt-5">
      <div class="col-xs-12 col-md-6 offset-md-3">
        <div class="card mt-5 nw-card">
          <div class="card-body text-left">
            <div class="text-center h2 nw-dark-blue">Admin Console Login</div>
            <div class="form-group">
              <label class="control-label">Username</label>
              <input class="form-control" type="text" v-model="model.userName"/>
            </div>
            <div class="form-group" v-show="status=='login'">
              <label class="control-label">Password</label>
              <input class="form-control" type="password" v-model="model.password"/>
            </div>
            <div class="form-group" v-show="showMFA==true">
              <label class="control-label">One-time Password</label>
              <input class="form-control" type="text" v-model="model.mfaCode"/>
            </div>
            <p v-show="showMFA==true"> {{ mfaMsg }}</p>
            <div class="col-xs-10" v-show="resp.length > 0">
              <div class="alert alert-danger" role="alert">
                <div v-for="(item,idx) in resp" :key="idx" :title="item.msg">
                  {{ item.msg }}
                </div>
              </div>
            </div>
            <div class="text-center mb-2">
                <button v-show="status=='login'" class="btn nw-btn-info text-uppercase" @click="login()">Login</button>
                <button v-show="status=='reset'" class="btn nw-btn-info text-uppercase" @click="sendReset()">Send Reset Link</button><br/>
                <a href="#" v-show="status=='login'" class="nw-dark-blue" @click="forgot()">Forgot Password?</a><br/>
                <button v-show="status=='reset'" class="btn nw-btn-outline-info" @click="back()">Cancel</button><br/>
            </div>
            <errors :resp="nc.resp" :retval="nc.retval"></errors>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NetClient from '@/util/netclient.js'
import Errors from '@/components/Errors'

export default {
  components: { Errors },
  data () {
    return {
      nc: NetClient,
      showMFA: false,
      mfaMsg: '',
      status: 'login',
      resp: [],
      model: {
          userName: '',
          password: '',
          mfaCode: ''
        }
      }
  },
  methods: {
    back () {
      NetClient.resp = []
      NetClient.retval = 'OK'
      this.status = 'login'
    },
    forgot () {
      NetClient.resp = []
      NetClient.retval = 'OK'
      this.status = 'reset'
    },

    sendReset () {
      NetClient.doRequest('login',{action: 'sendResetEmail', userName: this.model.userName}).then(msg => {
        console.log(msg)
      })
    },
    login () {
      NetClient.doRequest('login',{action: 'login', userName: this.model.userName, password: this.model.password, mfaCode: this.model.mfaCode}).then(msg => {
        switch(msg.data.mfaStatus)
        {
          case 'setup':
            this.$router.push({name: 'MFASetup'})
            break;
          case 'device':
            this.mfaMsg = 'Please enter a one-time password from your device app.'
            this.showMFA = true;
            break;

          case 'email':
            this.mfaMsg = 'Please enter a one-time password that was emailed to your address on file.'
            this.showMFA = true;
            break;

          case 'OK':
            if(msg.retval == 'OK') this.$router.push({name: 'SearchPatient'})
            break;

          default:
            console.log('Invalid mfaStatus: ' + msg.data.mfaStatus)
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
